import { forum } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      forumRequestLists: [],
      fields: [
        {
          key: "member.id",
          label: "ID",
          sortable: true,
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "exist",
          label: "Action",
          sortable: true,
        },
      ],
      tableData: [],
      key: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
    };
  },
  methods: {
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `?page=${this.pageNo}`;
        this.fetchData();
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData() {
      let url = forum.viewRequestList + "/" + this.$route.params.id;
      if (this.params) {
        url += this.params;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async approveRequest(val) {
      let url = null;
      try {
        url = forum.approveRequest + "/" + this.$route.params.id;
        let dataAppend = new FormData();
        if (val) {
          dataAppend.append("mobile_number", val);
        }
        const data = await this.postRequest(url, dataAppend);
        this.fetchData();
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Approved Successfully",
          });
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `?page=${value}`;
          this.fetchData();
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.name == "view-request-forum") {
      this.fetchData();
    }
  },
};
