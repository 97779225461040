<template>
    <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <div class="row" id="forum">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center mb-4">
                <div class="col-md-6">
                  <!-- <div class="form-inline navbar-search">
                    <div class="input-group">
                      <input name="title" class="form-control bg-light border-0 small" placeholder="Search..."
                        aria-label="Search" aria-describedby="basic-addon2" value="" v-model="filter"
                        v-on:keyup="search" />
                      <div class="input-group-append">
                        <button class="btn btn-search" @click="searchFor">
                          <i class="fas fa-search fa-sm"> </i>
                        </button>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="col-md-6">
                  <!-- <div class="all-tabs">
                    <div :class="activeTab == 'all' ? ' tab-options' : ''" @click.prevent="fetchData('all')"
                      class="mx-2 opt">
                      All
                    </div>
                    |
                    <div :class="activeTab == 'trash' ? ' tab-options' : ''" @click.prevent="fetchData('trash')"
                      class="mx-2 opt">
                      Trash
                    </div>
                    |
                    <div :class="activeTab == 'active' ? ' tab-options' : ''" @click.prevent="fetchData('active')"
                      class="mx-2 opt">
                      Active
                    </div>
                    <template v-if="can('add-forum')">
                      |
                      <div class="d-flex align-items-center">
                        <svg class="svg-inline--fa fa-table mx-2" height="16px" width="16px" aria-hidden="true"
                          focusable="false" data-prefix="fa" data-icon="table" role="img"
                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                          <path fill="currentColor"
                            d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z">
                          </path>
                        </svg>
                        <router-link :to="{ name: 'add-forum' }" class="opt">Add Forum</router-link>
                      </div>
                    </template>
                  </div> -->
                </div>
              </div>
              <div class="table-responsive mb-0">
                <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy" :per-page="0"
                  :current-page="currentPage" :sort-desc.sync="sortDesc" :filter-included-fields="filterOn"
                  @filtered="onFiltered" responsive="sm" class="table-bordered table-hover" :key="key">
                  <template v-slot:cell(name)="row">
                    <div v-if="(row.item.member != '' && row.item.member != null)">
                        <span v-if="(row.item.member.fname != '' && row.item.member.fname != null)">
                            {{ row.item.member.fname }}
                        </span>
                        <span v-if="(row.item.member.lname != '' && row.item.member.lname != null)">
                            {{ row.item.member.lname }}
                        </span>
                    </div>
                    <div v-else>NA</div>
                  </template>
                  <template v-slot:cell(mobile_number)="row">
                    <template v-if="row.item.member != '' && row.item.member != null">
                        <div v-if="(row.item.member.mobile_number != '' && row.item.member.mobile_number != null)">{{ row.item.member.mobile_number }}</div>
                        <div v-else>NA</div>
                    </template>
                    <template v-else>
                        <div>NA</div>
                    </template>
                  </template>
                  <template v-slot:cell(exist)="row">
                    <div class="btn btn-secondary" style="pointer-events: none; padding: 5px 10px" v-if="row.item.exist == 1">
                      <i class="mdi mdi-checkbox-marked-circle-outline mr-2"></i>
                      <span>Already Approved</span>
                    </div>
                    <div class="btn btn-success" style="padding: 5px 10px" @click.prevent="approveRequest(row.item.member.mobile_number, row.item.exist)" v-else-if="(row.item.member != null && row.item.member.mobile_number != null)">
                      <i class="mdi mdi-progress-check mr-2"></i>
                      <span>Approve</span>
                    </div>
                  </template>
                </b-table>
              </div>
              <template v-if="tableData.total_pages > 1">
                <div class="data_paginate">
                  <div class="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="currentPage" first-number last-number :total-rows="tableData.total"
                        :total-pages="tableData.total_pages" :per-page="tableData.per_page" :key="key"></b-pagination>
                    </ul>
                  </div>
                  <div class="form-inline navbar-search">
                    <span class="mr-2">Go to a Particular Paginated Page :</span>
                    <div class="input-group">
                      <b-form-input id="case" v-model="pageNo" placeholder="Enter Page No" class="in-width">
                      </b-form-input>
                      <div class="input-group-append">
                        <button class="btn btn-search" @click="filterPage">
                          <i class="fas fa-search fa-sm"> </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../mixins/request";
  import forumRequestMixin from "../../../mixins/ModuleJs/forum-request";
  
  export default {
    mixins: [MixinRequest, forumRequestMixin],
    data() {
      return {
        title: "Forum Requests",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "List",
          },
        ],
      };
    },
    components: {
      Layout,
      PageHeader,
    },
  };
  </script>
  
  
  